<template>
  <div class="form-bulk">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Products" route="website.products" />
        <div class="page-header">
          <h2 class="page-title">Bulk editor</h2>
        </div>
      </v-col>
    </v-row>
    <GroupSubmit mode="edit" :isLoading="isLoading" :callbackCancel="onCancel" :callbackSubmit="onSubmit" />
    <div v-if="!isLoadingPage" class="page-bulk--edit">
      <!-- <div v-if="!isLoadingPage" style="width: 100% !important" class="page-bulk--edit"> -->
      <div style="width: 100% !important" class="d-flex header-bulk--edit">
        <div style="min-width: 30px" class="">-</div>
        <div style="min-width: 300px">Title</div>
        <div>Tags</div>
        <div>Product Type</div>
        <div>Vendor</div>
        <div>Price</div>
        <div>Compare at price</div>
        <div>Cost per item</div>
        <div class="sku">Sku</div>
        <div style="min-width: 350px">Track Quantity</div>
        <div>Weight</div>
        <div style="min-width: 150px !important">WeightUnit</div>
      </div>
      <div style="width: 2484px !important">
        <v-divider></v-divider>
      </div>
      <div class="bulk-body--container">
        <div v-for="(product, index) in listProductEdit" :key="index" class="row-edit">
          <div style="position: relative" class="d-flex body-bulk--edit align-center">
            <div style="min-width: 30px">{{ index + 1 }}</div>
            <div style="min-width: 300px" class="title-product">{{ product.title }}</div>
            <div v-if="!onTags[index].display" @click="turnOnTags(index)">
              <v-chip-group class="chip-group" active-class="primary--text" column>
                <v-chip v-for="tag in product.tags" :key="tag">
                  {{ tag }}
                </v-chip>
              </v-chip-group>
              <!-- <v-text-field hide-details=""  :label="product.tags"></v-text-field> -->
            </div>
            <div
              v-if="onTags[index].display && btnOn == false"
              style="max-width: 196px"
              class="tag-container"
              v-click-outside="turnOffTags"
            >
              <v-combobox
                :loader-height="5"
                v-model.trim="product.tags"
                multiple
                small-chips
                hide-details
                :counter="2"
                append-icon=""
                :hide-selected="true"
                class="cbbox"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-if="data.item != ''"
                    close
                    color="primary"
                    small
                    label
                    :key="data.item"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </div>
            <div>
              <v-text-field hide-details v-model="product.productType"></v-text-field>
            </div>
            <div>
              <v-text-field hide-details v-model="product.vendor"></v-text-field>
            </div>
            <div class="disable-area">
              <v-text-field disabled="disabled" placeholder="-" hide-details></v-text-field>
            </div>
            <div class="disable-area">
              <v-text-field disabled="disabled" placeholder="-" hide-details></v-text-field>
            </div>
            <div class="disable-area">
              <v-text-field disabled="disabled" placeholder="-" hide-details></v-text-field>
            </div>
            <div class="disable-area">
              <v-text-field disabled="disabled" placeholder="-" hide-details></v-text-field>
            </div>
            <div class="disable-area" style="min-width: 350px">
              <v-text-field disabled="disabled" placeholder="-" hide-details></v-text-field>
            </div>
            <div class="disable-area">
              <v-text-field disabled="disabled" placeholder="-" hide-details></v-text-field>
            </div>
            <div class="disable-area">
              <v-text-field disabled="disabled" placeholder="-" hide-details></v-text-field>
            </div>
            <!-- <div class="disable-column">-</div> -->
          </div>
          <!-- <div style="width:100%">
        <v-divider class="mx-1"></v-divider>
      </div> -->
          <div v-for="variant in product.variants" :key="variant._id" class="d-flex body-bulk--edit align-center">
            <div style="min-width: 330px" class="pl-8">{{ variant.title }}</div>
            <div class="disable-area">
              <v-text-field disabled="disabled" placeholder="-" hide-details></v-text-field>
            </div>
            <div class="disable-area">
              <v-text-field disabled="disabled" placeholder="-" hide-details></v-text-field>
            </div>
            <div class="disable-area">
              <v-text-field disabled="disabled" placeholder="-" hide-details></v-text-field>
            </div>
            <div>
              <v-text-field
                :prefix="currency.currencySign()"
                hide-details
                type="number"
                v-model.number="variant.price"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                :prefix="currency.currencySign()"
                hide-details
                type="number"
                v-model.number="variant.compareAtPrice"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                :prefix="currency.currencySign()"
                hide-details
                type="number"
                v-model.number="variant.costPerItem"
              ></v-text-field>
            </div>
            <div>
              <v-text-field hide-details v-model="variant.sku"></v-text-field>
            </div>
            <div style="min-width: 350px">
              <v-select
                hide-details
                :items="[
                  { name: 'Hiweb track this product inventory', value: 'continue' },
                  { name: 'Dont track inventory', value: 'deny' },
                ]"
                v-model="variant.inventoryPolicy"
                item-value="value"
                item-text="name"
              ></v-select>
            </div>

            <div>
              <v-text-field hide-details v-model="variant.weight"></v-text-field>
            </div>
            <div style="min-width: 200px !important">
              <v-select
                hide-details
                style="min-width: 200px !important"
                v-model="variant.weightUnit"
                :items="['lb', 'oz', 'kg', 'g']"
              ></v-select>

              <!-- <v-text-field style="width:50px"  hide-details v-model="variant.weightUnit"></v-text-field> -->
            </div>
          </div>

          <!-- <pre>{{    <pre>{{ product }}</pre> }}</pre> -->
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 100%" v-else class="d-flex justify-center align-center">
      <page-loader />
    </div>
    <v-col cols="12" class="d-flex" v-if="!isLoadingPage">
      <v-spacer></v-spacer>
      <v-btn outlined class="mr-3" @click="onCancel">Cancel</v-btn>
      <v-btn :loading="isLoading" color="primary" @click="onSubmit">Save</v-btn>
    </v-col>
  </div>
</template>

<script>
import { productApi } from '@/apis/product';
import utilities from '@/helpers/utilities';
import { log } from 'handlebars';
import currency from '@/helpers/currency';
// import { directive as onClickaway } from 'vue-clickaway';
// import $ from 'jquery';
export default {
  data() {
    return {
      currency,
      listProduct: [],
      isLoading: false,
      isLoadingPage: false,
      listProductEdit: [],
      utilities,
      btnOn: true,
      onTags: [],
    };
  },
  methods: {
    turnOnTags(i) {
      if (this.btnOn) {
        this.btnOn = false;
        this.onTags[i].display = true;
      }
    },
    turnOffTags: function() {
      if (!this.btnOn) {
        this.onTags.forEach(i => {
          // console.log(i);
          if (i.display) i.display = false;
        });
        this.btnOn = true;
      }
    },
    // fix() {
    //   $(window).on('scroll', function() {
    //     var $w = $(window);
    //     $('.aa').css('top', $w.scrollTop());
    //   });
    // },
    async getDataProduct() {
      try {
        this.isLoadingPage = true;
        let a = await productApi.get({ ids: this.$route.query.ids, limit: 300 });
        console.log(a);
        this.listProduct = (await productApi.get({ ids: this.$route.query.ids, limit: 300 })).data.products.map(
          item => {
            return {
              ...item,
              variants: item.variants.filter(item => {
                return !item.isDefault;
              }),
            };
          },
        );
        this.listProductEdit = JSON.parse(JSON.stringify(this.listProduct));
        this.selectedTags;
        console.log(this.listProductEdit);
        this.onTags = this.listProductEdit.map(item => {
          console.log(111111111111);
          return { display: false };
        });
        // this.listProduct.map(item => {
        //   return { ...item };
        // });
        // console.log(this.listProduct, 'res');
      } catch (error) {
        console.log('error', error);
        //empty
      }
      this.isLoadingPage = false;
    },
    onCancel() {
      this.$router.push({ name: 'website.products' });
    },
    async onSubmit() {
      let isComparseVariant = true;
      let isNoti = false;
      for (let i = 0; i < this.listProductEdit.length; i++) {
        let comparse = utilities.comparisonTwoObject(this.listProduct[i], this.listProductEdit[i]);
        isComparseVariant = true;
        for (let index = 0; index < this.listProductEdit[i].variants.length; index++) {
          if (!isComparseVariant) {
            break;
          }
          isComparseVariant = utilities.comparisonTwoObject(
            this.listProduct[i].variants[index],
            this.listProductEdit[i].variants[index],
          );
          console.log(this.listProduct[i].variants[index], this.listProductEdit[i].variants[index]);
          console.log('isComparseVariant', isComparseVariant);
        }
        if (!comparse || !isComparseVariant) {
          try {
            productApi.update(this.listProductEdit[i]._id, this.listProductEdit[i]);
            isNoti = true;
          } catch (error) {
            //empty
            isNoti = false;
          }
          // console.log('listProductEdit', this.listProductEdit[i], 'listProduct', this.listProduct[i]);
        }
      }
      if (isNoti) {
        this.$store.commit('setMessages', {
          messages: 'Update bulk success',
          type: 'success',
        });
      } else {
        this.$store.commit('setMessages', {
          messages: 'Update bulk error',
          type: 'error',
        });
      }
    },
  },
  created() {
    // this.fix();
    console.log(this.$route);
    if (this.$route.query.ids) {
      this.getDataProduct();
    }
    this.onTags = this.listProductEdit.map(item => {
      // console.log(111111111111);
      return { display: false };
    });
  },
  watch: {
    // onTags: () => {
    //   this.listProduct.map(item => {
    //     return false;
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
// .page-bulk--edit::-webkit-scrollbar {
//   display: none;
// }
.form-bulk {
  min-width: 500px;
  max-width: 1200px;
  padding-left: 50px;
  padding-top: 50px;
}
.page-bulk--edit {
  width: 100% !important;
  overflow: auto;
  border: solid 1px #e6e6e6;
  // position: relative;
  background: white;
  div {
    padding: 1px;
  }
  .header-bulk--edit {
    // position: relative;
    font-weight: 600;
    div {
      min-width: 200px;
      font-size: 18px;
      // text-align: center;
    }
  }
  .bulk-body--container {
    height: 65vh;
    .body-bulk--edit {
      .chip-group {
        text-overflow: ellipsis;
        height: 41px;
        padding: 0;
      }
      .title-product {
        min-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
        color: black;
        font-size: 18px;
      }
      div {
        // text-align: center;
        min-width: 200px;
        .v-input {
          // min-width: 200px;
        }
      }
      .disable-area {
        cursor: no-drop;
      }
      .tag-container {
        position: absolute;
        z-index: 99999999999999999999;
        top: 1px;
        left: 331px;
        .cbbox {
          box-shadow: 1px 1px 10px 0px black;
          border-radius: 8px;
        }
      }
    }
  }
  .row-edit {
    width: 100%;
    div {
      // min-width: 50px;
    }
  }
}
</style>
